// // For glyphicon carets
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

@import "bootswatch_variables";
// @import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootswatch";

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("#{$icon-font-path}#{$icon-font-name}.eot");
  src: url("#{$icon-font-path}#{$icon-font-name}.eot?#iefix")
      format("embedded-opentype"),
    url("#{$icon-font-path}#{$icon-font-name}.woff") format("woff"),
    url("#{$icon-font-path}#{$icon-font-name}.ttf") format("truetype"),
    url("#{$icon-font-path}#{$icon-font-name}.svg#glyphicons-halflingsregular")
      format("svg");
}

// Stuff
.first-element {
  margin-top: 65px;
}

// Navigation
.navbar-brand > img {
  display: inline;
  margin: 0 10px;
  height: 100%;
  max-height: 45px;
  margin-left: 0;
}

.navbar-brand {
  padding: 0;
}

.navbar {
  -webkit-box-shadow: 0px 10px 24px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 24px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 24px -15px rgba(0, 0, 0, 0.75);
  background-color: white;
}

.navbar-center {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  margin: 0 auto;
}
.nav a {
  color: black;
}

// disable multiple br in course description
// reduce space needed to show
br + br {
  display: none;
}

// React Table
.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable {
  display: block;
  -webkit-display: flex;
  -moz-display: flex;
  -ms--display: flex;
}

.ReactTable {
  .-pagination .-btn {
    height: inherit;
  }
}

//highlight hover for react-table
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: $brand-warning;
}

// Glyphicon Fonts
.panel-heading .accordion-toggle:after {
  /* symbol for "opening" panels */
  font-family: "Glyphicons Halflings"; /* adjust as needed, taken from bootstrap.css */
  content: "\e114"; /* adjust as needed, taken from bootstrap.css */
  float: right; /* adjust as needed */
  color: grey; /* adjust as needed */
}
.panel-heading .accordion-toggle.collapsed:after {
  /* symbol for "collapsed" panels */
  font-family: "Glyphicons Halflings";
  content: "\e079"; /* adjust as needed, taken from bootstrap.css */
}

.ReactTable {
  height: 85vh;
  display: flex;
}
@media (max-height: 700px) {
  .ReactTable {
    height: 80vh;
    display: flex;
  }
}
@media (max-height: 500px) {
  .ReactTable {
    height: 70vh;
    display: flex;
  }
}

// React Big Calendar
.kurskatCal {
  height: 50vh;
  padding-top: 15px;
}
.absoluteKurskatCal {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: white;
  -webkit-box-shadow: 0px -10px 24px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -10px 24px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -10px 24px -15px rgba(0, 0, 0, 0.75);
}

.calendarclickdiv {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f4f4f4;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.calendarclick {
  color: rgba(0, 0, 0, 1);
  // margin-top: 0;
  // margin-bottom: 0;
  // padding-top: .5rem;
  // padding-bottom: .5rem;
  // padding-left: 1rem;
  // padding-right: 1rem;
}

.badge-orange {
  background-color: #f49800;
  font-size: 0.7em;
  line-height: 1.5;
  vertical-align: 3px;
}

.btn-emoji-rating {
  background-color: #fff;
  border: 2px solid #ddd;
  color: #000;
  font-size: 2em;
  margin-right: 8px;
}

.btn-emoji-rating:last-of-type {
  margin-right: 0;
}

@media (max-width: 992px) {
  .btn-emoji-rating {
    font-size: 1.2em;
  }
}

.btn-emoji-rating.selected {
  background-color: #dff0d8;
  border: 2px solid #d6e9c6;
  color: #000;
}

td.rating-description {
  vertical-align: top;
  padding: 0;
  font-size: 14px;
  padding-right: 15px;
}

@media (max-width: 992px) {
  .modal-lg {
    width: 95%;
  }
}

table.criteria-table {
  width: 100%;
}

.additionalInfoForm {
  border-color: #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
}
.additionalInfoFormOk {
  border-color: #c3e6cb;
  background-color: #d4edda;
  color: #155724;
}
.additionalInfoFormTextImportant {
  color: #721c24;
}
.additionalInfoFormText {
  margin: 10px 0;
  font-size: 13px;
}
.buttonsOuter {
  text-align: right;
}
.yourRating {
  margin-bottom: 10px;
}
.reviewDelimiter:last-of-type hr {
  display: none;
}
.modalReviewTeaser {
  text-align: center;
}
.emojiTitle {
  font-size: 100px;
}
.pleaseCompleteAll {
  font-size: 18px;
  font-weight: bold;
}
